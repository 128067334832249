@include breakpoint(mobileonly) {
    header {
        nav {
            &.navbar {
                margin-bottom: 0;
            }
        }
    }
    .container {
        padding-right: 0;
        padding-left: 0;
    }
    h1 {
        margin-top: 0;
        font-size: 22px;
    }
    h4 {
        text-align: center;
    }
    section {
        &.page-header {
            margin: 10px 0;

        }
        .row {
            margin-right: 0;
            margin-left: 0;
        }
    }
    button {
        &.btn {
            padding: 0 4px;
        }
    }
}
