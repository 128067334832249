#heating {
    > .row {
        margin-bottom: 40px;
        @include breakpoint(mobileonly) {
            margin-bottom: 0;
        }
    }
    .house{
        .floor1 {
            background: url(../img/house_floor1.png) no-repeat;
        }
        .floor2 {
            background: url(../img/house_floor2.png) no-repeat;

        }
        div {
            height: 100px;
            width: 162px;
            &:hover {
                cursor: pointer;
                background-color: #ccc;
            }
            &.active {
                background-color: #90c7ec;
            }
        }
    }
    @include breakpoint(mobileonly) {
        .center {
            text-align: center;
            div {
                margin: 0 auto;
            }
        }
    }
}