.map {
    width: 100%;
    height: 500px;
    .angular-google-map {
        width: 100%;
        height: 100%;
        .angular-google-map-container {
            width: 100%;
            height: 100%;
        }
    }
}