.red {
    color: $red;
    &:hover{
        color: $red-hover;
    }
}

.green {
    color: $green;
    &:hover{
        color: $green-hover;
    }
}

.blue {
    color: $blue;
    &:hover{
        color: $blue-hover;
    }
}