/* LAYOUT START */
body {
    > header {
        padding: 20px;
        nav {
            a {
                //text-transform: capitalize;
                
            }
        }
    }

    > footer {
        padding: 20px;
    }
}
/* LAYOUT END */