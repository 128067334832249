.grid-table {
    > div {
      border-bottom: 1px solid #ccc;
      padding: 4px 0;           
    }

    > *:first-of-type {
          background-color: #ccc;
    }
    

}