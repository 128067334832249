html, body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    * {
        box-sizing: border-box;
    }
}

#dashboard {
    $theme-color: #00b3ee;
    color: $theme-color;

    $font-size: 1vw;

    * {
        font-size: $font-size;
    }

    .widget {
        border: 1px dashed $theme-color;
        margin: 1% 0 0 1%;
        float: left;

        width: calc(32%);
        height: calc(31%);

        h2 {
            color: #fff;
            margin: 0;
            font-size: $font-size;
            font-weight: bold;
            padding: 8px 4px;
            background-color: $theme-color;
        }

        p {
            padding: 8px 4px;
        }
    }
}